import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { useMemo } from "react";
import useSWR from "swr";
import {
    callGetMedia,
    callListMedia,
    callListMediaByFolder,
    callListMediasByClass,
    fetchMedias,
} from "@/hooks/media/graphqlUtils";
import {
    fetchChapters,
    fetchEmbeddings,
    fetchSuggestedQuestions,
    fetchTranscript,
    fetchUtterances,
    getChaptersUrl,
    getEmbeddingsUrl,
    getMediaUrl,
    getSpriteSheetVTTUrl,
    getSubtitlesUrl,
    getSuggestedQuestionsUrl,
    getTranscriptUrl,
    getUtterancesUrl,
    resolveListMediaSWRKey,
    resolveMediaChaptersSWRKey,
    resolveMediaSWRKey,
} from "@/hooks/media/utils";
import { isExcel, isPDF, isPPT, isVideo } from "@/hooks/media/checkers";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { platform } from "@/platform";
import { ItemType, Media, MediaChapterInput, MediaType, TranscriptEnum } from "@knowt/syncing/graphql/schema";
import { fromEntries } from "@/utils/genericUtils";
import { filterClassItems } from "@/utils/course";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const useMedia = ({
    mediaId,
    fallbackData,
    revalidateOnFocus = false,
    isEnabled = true,
}: {
    mediaId: string | null;
    fallbackData?: Media;
    revalidateOnFocus?: boolean;
    isEnabled?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const { data: media, mutate } = useSWR(
        resolveMediaSWRKey({ mediaId, isEnabled }),
        async ([_, mediaId]) => {
            const storage = await platform.storage();
            const password = (await storage.getWithExpiry(`${ItemType.MEDIA}-${mediaId}-pwd`)) as string;
            return await callGetMedia({ mediaId, password });
        },
        { fallbackData, revalidateOnFocus }
    );

    const mediaUrl = useMemo(() => {
        return getMediaUrl({ media });
    }, [media]);

    const isMediaOwner = useMemo(() => {
        return media?.userId === userId;
    }, [media, userId]);

    const transcriptUrl =
        media &&
        media?.transcript !== TranscriptEnum.NONE &&
        media?.transcript !== TranscriptEnum.FREE &&
        getTranscriptUrl({ media });

    const transcriptPreviewUrl =
        media &&
        media?.transcript === TranscriptEnum.FREE &&
        [MediaType.VIDEO, MediaType.AUDIO].includes(media.type) &&
        getTranscriptUrl({
            media,
            preview: true,
        });

    const embeddingsUrl = media && getEmbeddingsUrl({ media });

    const utterancesUrl =
        media &&
        media?.transcript === TranscriptEnum.FULL &&
        [MediaType.VIDEO, MediaType.AUDIO].includes(media.type) &&
        getUtterancesUrl({ media });

    const suggestedQuestionsUrl =
        media &&
        [TranscriptEnum.FULL, TranscriptEnum.MIN].includes(media.transcript) &&
        getSuggestedQuestionsUrl({ media });

    const spriteSheetUrl = media?.type === MediaType.VIDEO ? getSpriteSheetVTTUrl({ media }) : undefined;
    const subtitlesUrl = media?.type === MediaType.VIDEO ? getSubtitlesUrl({ media }) : undefined;

    const chaptersUrl =
        media && [MediaType.VIDEO, MediaType.AUDIO].includes(media.type) ? getChaptersUrl({ media }) : undefined;

    const { data: transcript } = useSWR({ transcriptUrl }, fetchTranscript);

    const { data: transcriptPreview } = useSWR(
        { transcriptPreviewUrl },
        async ({ transcriptPreviewUrl: transcriptUrl }) => await fetchTranscript({ transcriptUrl })
    );

    const { data: utterances } = useSWR({ utterancesUrl }, fetchUtterances);
    const { data: embeddings } = useSWR({ embeddingsUrl }, fetchEmbeddings);

    const { data: suggestedQuestions } = useSWR({ suggestedQuestionsUrl }, fetchSuggestedQuestions);

    const { data: chapters } = useSWR(
        resolveMediaChaptersSWRKey({
            media,
        }),
        async ([_, chaptersUrl]) => {
            return await fetchChapters({ chaptersUrl });
        }
    );

    const stringifiedTranscript = useMemo(() => {
        if (media?.type === MediaType.VIDEO) {
            let chapterIdx = 0;
            return transcript
                ?.map(({ content, start }) => {
                    if (!chapters || !chapters.length) {
                        return `\n${content}\n`;
                    }

                    let placeChapter = false;
                    // Find out if we need to insert the chapter block
                    if (start >= chapters[chapterIdx]?.start) {
                        placeChapter = true;
                        chapterIdx++;
                    }

                    // Construct the transcript entry with the title, adding the chapter if necessary
                    return (
                        (placeChapter ? `\nChapter ${chapterIdx}: ${chapters[chapterIdx - 1]?.title}\n` : "") +
                        `${content}`
                    );
                })
                .join("\n")
                .trim();
        } else {
            return transcript
                ?.map(({ content }, index) => `\nPage ${index + 1}:\n${content}\n`)
                .join("\n")
                .trim();
        }
    }, [media?.type, transcript, chapters]);

    return {
        media,
        mediaUrl,
        transcript,
        stringifiedTranscript,
        utterances,
        embeddings,
        spriteSheetUrl,
        subtitlesUrl,
        chaptersUrl: chapters?.length ? chaptersUrl : undefined,
        chapters: chapters as MediaChapterInput[],
        isMediaOwner,
        readOnly: !isMediaOwner,
        mutate,
        suggestedQuestions,
        transcriptPreview,
        isPPT: isPPT(media),
        isXLS: isExcel(media),
        isPDF: isPDF(media),
        isVideo: isVideo(media),
        isFile: !isVideo(media),
    };
};

export const useAllMedias = ({
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, Media>;
}) => {
    const { userId } = useCurrentUser();

    const { data: allMedias, mutate } = useSWRImmutable(
        resolveListMediaSWRKey({ userId, isEnabled }),
        async ([_, userId, _folderId]) => {
            return await callListMedia({ userId });
        },
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const medias = useMemo((): Record<string, Media> => {
        if (!allMedias) return {};

        return fromEntries(
            Object.values(allMedias)
                // TODO: pass __typename from backend @asp3
                .map(media => ({ ...media, __typename: "Media" }))
                .filter(media => !!media.trash === inTrash)
                .map(media => [media.mediaId, media])
        ) as Record<string, Media>;
    }, [allMedias, inTrash]);

    return { medias, mutate };
};

export const useMedias = (mediaIds: string[], isEnabled = true) => {
    const { data: medias } = useSWR(isEnabled && mediaIds && ["getMedias", mediaIds], async ([_, mediaIds]) =>
        fetchMedias(mediaIds)
    );

    return { medias };
};

export const useFolderMedias = ({
    folderId,
    inTrash = false,
    fallbackData,
}: {
    folderId: string;
    inTrash?: boolean;
    fallbackData?: Record<string, Media>;
}) => {
    const { userId } = useCurrentUser();

    const { data: allMedias, mutate } = useSWR(
        resolveListMediaSWRKey({ userId, folderId }),
        async ([_, _userId, folderId]) => callListMediaByFolder({ folderId }),
        {
            fallbackData,
        }
    );

    const folderMedias = useMemo((): Record<string, Media> => {
        if (!allMedias) return {};

        return fromEntries(
            Object.values(allMedias)
                .filter(media => !!media.trash === inTrash && media.folderId === folderId)
                .map(media => [media.mediaId, media])
        );
    }, [allMedias, folderId, inTrash]);

    return { folderMedias, mutate };
};

export const useClassMedias = ({
    classId,
    sectionId,
    inTrash = false,
    fallbackData,
}: {
    classId: string;
    sectionId?: string | null | undefined;
    inTrash?: boolean;
    fallbackData?: Record<string, Media> | undefined;
}) => {
    const { user, userId } = useCurrentUser();

    const {
        data: allMedias,
        mutate,
        error,
    } = useSWRImmutable(resolveListMediaSWRKey({ userId, classId }), () => callListMediasByClass({ classId }), {
        fallbackData,
        use: [populateCacheWithFallbackData],
    });

    const classMedias = useMemo((): Record<string, Media> => {
        if (!classId || !allMedias) return {};

        return fromEntries(filterClassItems({ user, classId, items: allMedias, inTrash, sectionId }));
    }, [allMedias, inTrash, user, sectionId, classId]);

    return { classMedias, mutate, isLoading: !allMedias && !error };
};
