import { SectionHeader } from "@/components/Containers";
import { FlexColumn } from "@/components/Flex";
import UserContentCard from "@/components/cards/UserContentCard";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";

// TODO(import): workaround to allow imports to work. https://github.com/vercel/next.js/issues/49757
import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { spacing } from "@/utils/spacing";
import { isNote } from "@knowt/syncing/utils/dataCleaning";

type ExplopreTopUserContentsProps =
    | {
          notes: Note[];
          flashcardSets?: never;
          title?: string;
          type?: string;
      }
    | {
          notes?: never;
          flashcardSets: FlashcardSet[];
          title?: string;
          type?: string;
      };

const ExploreTopUserContents = ({ notes, flashcardSets, title, type }: ExplopreTopUserContentsProps) => {
    const items = notes ?? flashcardSets;

    if (!items.length) {
        return null;
    }

    return (
        <FlexColumn
            style={{
                gap: spacing.SM,
                width: "100%",
            }}>
            <SectionHeader>{title ?? `Explore top ${type ?? ""} ${notes ? "notes" : "flashcards"}`}</SectionHeader>
            <RepeatAutoFillGrid itemMinWidth="24rem" style={{ gap: "3rem" }}>
                {items.map((item: Note | FlashcardSet, i) => (
                    <UserContentCard
                        key={isNote(item) ? item.noteId : item.flashcardSetId + i}
                        {...(isNote(item) ? { note: item } : { flashcardSet: item })}
                    />
                ))}
            </RepeatAutoFillGrid>
        </FlexColumn>
    );
};

export default ExploreTopUserContents;
