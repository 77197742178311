import { HelpCircle } from "lucide-react";
import ConditionalTooltip from "../../wrappers/ConditionalTooltip";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";

export const MoreInfoTooltipButton = ({ tooltip }) => {
    return (
        <ConditionalTooltip tooltip={tooltip}>
            <HelpCircle size={iconSizes.SM} stroke={themeColors.neutral2} style={{ flexShrink: 0 }} />
        </ConditionalTooltip>
    );
};

export default MoreInfoTooltipButton;
