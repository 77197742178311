import { SxProps } from "@mui/material/index";
import { useState } from "react";
import { themeColors } from "@/utils/themeColors";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";

const ToggleSwitch = ({
    checked,
    onChange,
    sx,
    thumbStyle,
    isOn,
    disabled = false,
    checkedColor,
    uncheckedColor,
}: {
    checked: boolean;
    onChange: (newCheckValue: boolean) => void;
    sx?: SxProps & { height: string; width: string };
    thumbStyle?: React.CSSProperties;
    isOn?: boolean;
    disabled?: boolean;
    checkedColor?: string;
    uncheckedColor?: string;
}) => {
    const [isChecked, setIsChecked] = useState(checked);

    const isSwitchOn = isOn ?? isChecked;

    return (
        <CircularRectTextButton
            onClick={() => {
                if (!disabled) {
                    setIsChecked(!isChecked);
                }
                onChange(!isChecked);
            }}
            sx={{
                position: "relative",
                width: "6rem",
                height: "3rem",
                backgroundColor: isSwitchOn
                    ? checkedColor || themeColors.neutral3
                    : uncheckedColor || themeColors.neutral2,
                transition: "background-color 0.2s ease-in-out",
                boxShadow: null,
                ...sx,
                "&:hover": {
                    backgroundColor: isSwitchOn
                        ? checkedColor || themeColors.neutral2
                        : uncheckedColor || themeColors.neutral3,

                    opacity: isSwitchOn ? (checkedColor ? 0.5 : 1) : uncheckedColor ? 0.5 : 1,
                },
            }}>
            <span
                style={{
                    position: "absolute",
                    top: "2.5%",
                    left: isSwitchOn ? (sx?.width ? `calc(${sx.width})` : "3rem") : "0.2rem",
                    borderRadius: "100%",
                    width: "46%",
                    height: "95%",
                    backgroundColor: themeColors.neutralWhite,
                    transition: "left 0.2s ease-in-out",
                    ...thumbStyle,
                }}
            />
        </CircularRectTextButton>
    );
};

export default ToggleSwitch;
