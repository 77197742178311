import { Rem } from "@/types/common";

const RepeatAutoFillGrid = ({
    children,
    itemMinWidth,
    style,
}: {
    children: React.ReactNode;
    itemMinWidth: Rem;
    style?: React.CSSProperties;
}) => {
    return (
        <div
            style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, minmax(${itemMinWidth},1fr))`,
                ...style,
            }}>
            {children}
        </div>
    );
};

export default RepeatAutoFillGrid;
